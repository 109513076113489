import React from 'react';
import Layout from '../components/Layout';

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/products/banner/6.jpg)`,
                        }}
                    >
                        <h1>Secador Centrífuga</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Zion Tech suministra dos tipos de secadores de plásticos. La tasa de humedad de las
                                escamas de PET se reducirá a 2 ~ 3% después de la fuerza de deshidratación, si el
                                intercambiador de calor se complementa posteriormente, la tasa de humedad se puede
                                reducir aún más al 0,7%. Es suficiente para satisfacer las demandas de las aplicaciones
                                posteriores. El eje especialmente diseñado y las mallas antibloqueo son beneficiosas
                                para alcanzar la máxima capacidad de salida y el mínimo contenido de humedad. El secador
                                es una ventaja de la tasa de avería más baja y el mantenimiento más simple. La próxima
                                generación de secadores con un tipo de escalón diseño de eje, que es útil para separar
                                el agua y tiene una función de limpieza para las escamas de PET
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Caracteristicas</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                El contenido de humedad de las escamas de rPET es un criterio importante para el
                                reciclaje de PET, la eficiencia de deshidratación mecánica afecta directamente a las
                                escamas de rPET y al consumo de energía durante el proceso de reciclaje. El secador de
                                plástico separa el agua mediante la fuerza de deshidratación centrífuga. Las escamas de
                                PET se introducen en el secador a través de la entrada tolva, moviéndose y hacia
                                adelante por las paletas del rotor en la secadora. La última sección del eje tiene un
                                diseño similar a un soplador que puede transportar PET al siguiente proceso. El agua se
                                expulsa a través de la pantalla y se acumula en la tolva de descarga en la parte
                                inferior de la máquina de deshidratación y el desagüe
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Capacidad de entrada</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                1500-6000 kg / h
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
